// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/Footer/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/Footer/index.tsx");
  import.meta.hot.lastModified = "1729211991236.1233";
}
// REMIX HMR END

import React from "react";
import { LuInstagram } from "react-icons/lu";
import { AiFillTikTok } from "react-icons/ai";
import { AiOutlineFacebook } from "react-icons/ai";
import { CiYoutube } from "react-icons/ci";
const Footer = () => {
  return <section id="footer-section" className="relative pb-28">
      <div className="container mx-auto lg:bottom-0 lg:right-0 lg:left-0 lg:h-[50vh] xl:h-[60vh]">
        <div className="grid grid-cols-1 gap-2 pb-6 border-b border-black md:gap-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="w-full mr-10 sm:ml-10 md:ml-0 sm:mb-4 md:w-7/12 lg:w-9/12">
            <ul>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com" className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  MásPreparación.com
                </a>
                <p className="text-sm text-black md:text-base font-space-grotesk">
                  Asegura tu futuro
                </p>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://www.google.com.mx/maps/place/Preparaci%C3%B3n+Polit%C3%A9cnico/@19.4959129,-99.1412367,20.25z/data=!4m15!1m8!3m7!1s0x85d1f9b3c37496af:0xf16fd9c060b95262!2sAv.+Wilfrido+Massieu+684,+Nueva+Industrial+Vallejo,+Gustavo+A.+Madero,+07739+Ciudad+de+M%C3%A9xico,+CDMX!3b1!8m2!3d19.4961311!4d-99.1409671!16s%2Fg%2F11c43wrrnn!3m5!1s0x85d1f9b39bae8ecb:0x61bde17284981d24!8m2!3d19.4961144!4d-99.14094!16s%2Fg%2F11cn0y3hrn?entry=ttu&g_ep=EgoyMDI0MDkxMS4wIKXMDSoASAFQAw%3D%3D" className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  Ubicación
                </a>
                <p className="text-sm text-black md:text-base font-space-grotesk">
                  Wilfrido Massieu 684 Col. Planetario Lindavista. CDMX, México.
                </p>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a
              //href="https://stg.maspreparacion.com/es/regularizaciones"
              className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  Contacto
                </a>
                <p className="text-sm text-black break-words md:text-base font-space-grotesk">
                  ayuda@preparacionpolitecnico.mx
                </p>
                <p className="text-sm text-black md:text-base font-space-grotesk">
                  55 8848 3185
                </p>
              </li>
            </ul>
          </div>

          <div className="hidden mr-10 lg:block">
            <ul>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com/es/universidades" className="font-bold text-black font-space-grotesk hover:text-yellow-500">
                  Admisión universidades
                </a>
                <p className="text-black font-space-grotesk">IPN, UNAM, UAM</p>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com/es/preparatoria" className="font-bold text-black font-space-grotesk hover:text-yellow-500">
                  Admisión preparatoria
                </a>
                <p className="text-black font-space-grotesk">COMIPEMS</p>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com/es/regularizaciones" className="font-bold text-black font-space-grotesk hover:text-yellow-500">
                  Regularizaciones universidades
                </a>
                <p className="text-black font-space-grotesk">
                  Mejora tus habilidades
                </p>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com/es/regularizaciones" className="font-bold text-black font-space-grotesk hover:text-yellow-500">
                  Regularizaciones preparatoria
                </a>
                <p className="text-black font-space-grotesk">
                  Se el mejor de tu clase
                </p>
              </li>
            </ul>
          </div>

          <div className="mr-10">
            <ul>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com/es/nosotros" className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  Nosotros
                </a>
                <p className="text-sm text-black md:text-base font-space-grotesk">
                  Conoce nuestra historia
                </p>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com/es/nosotros" className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  Testimoniales
                </a>
                <p className="text-sm text-black md:text-base font-space-grotesk">
                  Casos de éxito
                </p>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com/es/nosotros" className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  Preguntas frecuentes
                </a>
                <p className="text-sm text-black md:text-base font-space-grotesk">
                  ¿Tienes dudas?
                </p>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com/es/nosotros" className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  Planteles
                </a>
                <p className="text-sm text-black md:text-base font-space-grotesk">
                  Ven a conocernos
                </p>
              </li>
            </ul>
          </div>

          <div className="hidden mr-10 lg:block">
            <ul>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com" className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  Inicio
                </a>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://stg.maspreparacion.com/es/blog" className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  Blog
                </a>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://www.alumnos.preparacionpolitecnico.mx/" className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  Iniciar sesión
                </a>
              </li>
              <li className="mb-2.5 sm:mb-6">
                <a href="https://www.alumnos.preparacionpolitecnico.mx/" className="text-sm font-bold text-black md:text-base font-space-grotesk hover:text-yellow-500">
                  Crear cuenta
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 mt-2 md:grid-cols-2 lg:grid-cols-4 md:gap-6">
          <div className="inline text-sm font-bold text-black font-space-grotesk hover:text-yellow-500">
            © Copyright Preparación Politécnico 2024.
          </div>
          <div>
            <a href="https://www.preparacionpolitecnico.mx/terminos" className="inline-flex text-sm font-bold text-black font-space-grotesk hover:text-yellow-500">
              Términos y Condiciones
            </a>
          </div>
          <div>
            <a href="https://www.preparacionpolitecnico.mx/aviso" className="inline-flex text-sm font-bold text-black font-space-grotesk hover:text-yellow-500">
              Aviso de Privacidad
            </a>
          </div>
          <div>
            <a href="https://www.youtube.com/@PreparacionPolitecnico" target="_blank" rel="noopener noreferrer" className="flex inline-flex items-center justify-center w-10 h-10 mr-4 text-black bg-yellow-400 rounded-full hover:text-yellow-500">
              <CiYoutube className="w-6 h-6" />
            </a>
            <a href="https://www.facebook.com/PreparacionIPN" target="_blank" rel="noopener noreferrer" className="flex inline-flex items-center justify-center w-10 h-10 mr-4 text-black bg-yellow-400 rounded-full hover:text-yellow-500">
              <AiOutlineFacebook className="w-6 h-6" />
            </a>
            <a href="https://www.instagram.com/preparacion_politecnico/" target="_blank" rel="noopener noreferrer" className="flex inline-flex items-center justify-center w-10 h-10 mr-4 text-black bg-yellow-400 rounded-full hover:text-yellow-500">
              <LuInstagram className="w-6 h-6" />
            </a>
            <a href="https://www.tiktok.com/@preparacionpolitecnico" target="_blank" rel="noopener noreferrer" className="flex inline-flex items-center justify-center w-10 h-10 text-black bg-yellow-400 rounded-full hover:text-yellow-500">
              <AiFillTikTok className="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
    </section>;
};
_c = Footer;
export default Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;