// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/HomeComponents/Menu/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/HomeComponents/Menu/index.tsx");
  import.meta.hot.lastModified = "1729797771131.2874";
}
// REMIX HMR END

import React, { useEffect, useState } from "react";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { Squash } from "hamburger-react";
import { useNavigate, useSearchParams } from "@remix-run/react";
import logoWhite from "~/assets/logos/logoWhite.png";
import { handleScrollHelper } from "~/utils/click-helpers";
import AppearWrapper from "../Animation/Appear.wrapper";
const Ribbon = () => {
  _s();
  const [isHidden, setIsHidden] = useState(true);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const {
    scrollY
  } = useScroll();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const scrollTo = searchParams.get("scrollTo");
    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        const yOffset = -50;
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({
          top: y,
          behavior: "smooth"
        });
      }
    }
  }, [searchParams]);
  useMotionValueEvent(scrollY, "change", y => {
    if (isInitialRender) {
      setTimeout(() => setIsInitialRender(false), 1000);
      return;
    }
    if (y > 500 && y < 9000) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  });
  return <motion.div className="fixed w-full bottom-4" animate={isHidden ? "hidden" : "visible"} variants={{
    initial: {
      y: "150%",
      opacity: 0
    },
    hidden: {
      y: "150%",
      opacity: 0
    },
    visible: {
      y: "0%",
      opacity: 1
    }
  }}>
      {!isInitialRender && <div id="ribbon-banner" className="relative w-full my-4 overflow-visible text-lg text-center sm:text-xs sm:text-base md:text-lg">
          <a className="inline-block px-5 py-2 font-bold text-white transition-all bg-yellow-600 rounded-full shadow-xl shadow-2xl select-none hover:scale-110 hover:bg-yellow-500 hover:text-white prep-cta" href="#hero-section" onClick={e => handleScrollHelper(e, "hero-section")}>
            ¡Comienza hoy mismo!
          </a>
        </div>}
    </motion.div>;
};
_s(Ribbon, "u4shB6jWifRsn7ZTrY36OsfVRgQ=", false, function () {
  return [useScroll, useSearchParams, useMotionValueEvent];
});
_c = Ribbon;
const NavLink = ({
  href,
  onClick,
  className = "",
  children,
  target
}) => <a href={href} onClick={onClick} target={target} className={`text-white transition-all ${className}`}>
    {children}
  </a>;
_c2 = NavLink;
const Menu = () => {
  _s2();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const navLinks = [{
    href: "#popular-courses",
    text: "Cursos"
  }, {
    href: "#about-section",
    text: "Informes"
  }, {
    href: "#faq-section",
    text: "FAQs"
  }, {
    href: "#pricing-section",
    text: "Precios"
  }];
  const authLinks = [{
    href: `${ENV.APP_URL}/sign-in`,
    text: "Iniciar sesión",
    className: "bg-blue-600 hover:bg-blue-500"
  }, {
    href: `${ENV.APP_URL}/sign-up`,
    text: "Crear cuenta",
    className: "bg-yellow-600 hover:bg-yellow-500"
  }];
  const renderNavLinks = (isMobile = false) => navLinks.map(({
    href,
    text
  }) => <NavLink key={href} href={href} onClick={e => handleScrollHelper(e, href.slice(1), () => setIsOpen(false))} className={isMobile ? "block mb-8 text-white hover:text-gray-400" : "py-1 hover:text-gray-500 hover:scale-110"}>
        {text}
      </NavLink>);
  const renderAuthLinks = (isMobile = false) => authLinks.map(({
    href,
    text,
    className
  }) => <NavLink key={href} href={href} className={`${isMobile ? "block px-3 py-2 mb-8 text-xl inline-block" : "inline-block px-4 py-1 font-bold"} ${className} rounded-full select-none hover:scale-110 hover:text-white`}>
        {text}
      </NavLink>);
  return <nav className="fixed z-40 w-full">
      <Ribbon />
      <div className="relative right-0 border-b bg-deep-black/70 z-70 border-zinc-800 backdrop-blur-md">
        <div className="container flex items-center justify-between p-4 mx-auto h-14 md:h-20 menu-content">
          <div className="site-logo">
            <motion.div whileHover={{
            scale: 1.2
          }} whileTap={{
            scale: 0.9
          }} transition={{
            type: "spring",
            stiffness: 400,
            damping: 17
          }}>
              <NavLink href="#hero-section" onClick={e => handleScrollHelper(e, "hero-section", () => setIsOpen(false))}>
                <img src={logoWhite} alt="Preparación Politécnico" className="w-24 md:w-32" />
              </NavLink>
            </motion.div>
          </div>
          <div className="hidden space-x-4 md:flex">
            {renderNavLinks()}
            {renderAuthLinks()}
          </div>
          <div className="relative right-0 md:hidden z-70">
            <div onClick={() => setIsOpen(!isOpen)} className="text-white cursor-pointer focus:outline-none">
              <Squash toggled={isOpen} color="#FFFFFF" />
            </div>
          </div>
        </div>
      </div>

      {isOpen && <div className="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center h-screen overflow-hidden text-4xl text-white transition-all bg-deep-black/60 backdrop-blur-xl z-60 md:hidden">
          <div className="w-8/12">
            {navLinks.map(({
          href,
          text
        }, index) => <AppearWrapper key={href} delay={0.1 * (index + 1)}>
                {renderNavLinks(true)[index]}
              </AppearWrapper>)}
            {authLinks.map(({
          href
        }, index) => <AppearWrapper key={href} delay={0.4}>
                {renderAuthLinks(true)[index]}
              </AppearWrapper>)}
          </div>
        </div>}
    </nav>;
};
_s2(Menu, "4nYqkg6r9GswevkfG8EhSEEcqSs=", false, function () {
  return [useNavigate];
});
_c3 = Menu;
export default Menu;
var _c, _c2, _c3;
$RefreshReg$(_c, "Ribbon");
$RefreshReg$(_c2, "NavLink");
$RefreshReg$(_c3, "Menu");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;