// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_main.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_main.tsx");
}
// REMIX HMR END

import { Outlet } from "@remix-run/react";
import Footer from "~/components/HomeComponents/Footer";
import Menu from "~/components/HomeComponents/Menu";
import homeStyles from "~/home.css";

// swipper styles
import swiperStyles from "swiper/css";
import autoplayStyles from "swiper/css/autoplay";
import effectStyles from "swiper/css/effect-flip";
import freeStyles from "swiper/css/free-mode";
import navigationStyles from "swiper/css/navigation";
import paginationStyles from "swiper/css/pagination";
import thumbsStyles from "swiper/css/thumbs";
export const links = () => [{
  rel: "stylesheet",
  href: swiperStyles
}, {
  rel: "stylesheet",
  href: autoplayStyles
}, {
  rel: "stylesheet",
  href: effectStyles
}, {
  rel: "stylesheet",
  href: freeStyles
}, {
  rel: "stylesheet",
  href: navigationStyles
}, {
  rel: "stylesheet",
  href: paginationStyles
}, {
  rel: "stylesheet",
  href: thumbsStyles
}, {
  rel: "stylesheet",
  href: homeStyles
}];
export default function HomeRoute() {
  return <div id="root" className="bg-deep-black">
      <Menu></Menu>
      <Outlet />
      <Footer></Footer>
    </div>;
}
_c = HomeRoute;
var _c;
$RefreshReg$(_c, "HomeRoute");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;